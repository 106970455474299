import React from 'react';
import Options from './Options';
import PlaneImg from './PlaneImg';
import Nav from './Nav';

class Quiz extends React.Component {
    constructor(props) {
        super(props);
        var json = require('../planes.json'); //(with path)
        var answer = json.find(x => x.Varient === this.props.varient);
        var alternatives = (answer.Alternatives + ','+answer.Varient).split(',');
        this.state = {
            planesData: json, 
            diffTable: '',
            simiTable: '',
            varients: json.map((m) => m.Varient),
            answer: answer,
            selected: '',
            answardCorrect: null,
            alternatives: this.shuffle(alternatives)
        };
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick(event){
        
        var selected = this.state.planesData.find(x => x.Varient === event.target.getAttribute('data-plane'));
        var answer = this.state.answer;
        const answardCorrect = this.state.answer.Varient === selected.Varient;
        
        var differences = Object.keys(selected).filter(k => selected[k] !== answer[k]);    
        var diffTable = differences.map((diff, i) => i <= 3 ? null: //First difference is model, Second is variant, third is alternatives, forth is wiki url
                <tr>
                    <td>{diff}</td>
                    <td>{answer[diff]}</td>
                    <td>{selected[diff]}</td>                            
                </tr>
        );

        var similarities = Object.keys(selected).filter(k => selected[k] === answer[k] && answer[k] !== '');    
        var simiTable = similarities.map((simi, i) => (i <= 3 && answardCorrect) ? null: //First difference is model, Second is variant, third is alternatives, forth is wiki url
                <tr>
                    <td>{simi}</td>
                    <td>{answer[simi]}</td>
                    <td>{selected[simi]}</td>                            
                </tr>
        );
        
        this.setState({
            answardCorrect: answardCorrect,
            diffTable: diffTable, 
            simiTable: simiTable,
            selected: selected.Varient
        });
    }

    shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    render(){
        console.log(this.props);

        return (
            <div className="quizContainer">                              
              
              
                <PlaneImg varient={this.props.varient} />
                <Options 
                    varients={this.state.varients} 
                    onClick={this.handleClick} 
                    alternatives={this.state.alternatives}
                />  
            {this.state.answardCorrect &&<div className="alert alert-success" role="alert"> 
               Correct! You can read more about {this.state.selected} on <a href={this.state.answer.Wikipedia} target="_blank" rel="noopener noreferrer" >Wikipadia</a>.       
            </div>}      
              {this.state.answardCorrect && <Nav onClickNext={this.props.onClickNext} />     }           

                {(this.state.selected !== '' &&  !this.state.answardCorrect) && 
                <table className={"zui-table "+ (this.state.answardCorrect ? "CorrectTable" : "wrongTable")}>
                    <thead>
                        <tr>
                            <th>Defference</th>
                            <th>This plane (this variant)</th>
                            <th>{this.state.selected}</th>                            
                        </tr>
                    </thead>
                    <tbody>                
                        {this.state.diffTable}
                    </tbody>
                </table>}
                {(this.state.selected !== '') && 
                <table className={"zui-table "+ (this.state.answardCorrect ? "CorrectTable" : "wrongTable")}>
                    <thead>
                        <tr>
                            <th>Similarity</th>
                            <th>This plane (this variant)</th>
                            <th>{this.state.selected}</th>                            
                        </tr>
                    </thead>
                    <tbody>                
                        {this.state.simiTable}
                    </tbody>
                </table>}                               
            </div>
        );
    }    
}

export default Quiz;