import React from "react";
import bootstrap from "../bootstrap.min.css";

class Options extends React.Component {
  
  render() {
    const answersLis = this.props.alternatives.map((answer, i) => (
      <button
        onClick={this.props.onClick}
        className="btn OptionBtn"//"btn btn-outline-primary2"
        key={"answer_" + i}
        data-plane={answer}
      >
        {answer}
      </button>
    ));
    return <div className="OptionsContainer">{answersLis}</div>;
  }
}

export default Options;
