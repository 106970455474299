import React from 'react';
import './index.css';
import QuizMaster from './components/QuizMaster';
import FooterPage from './components/FooterPage'

function App() {



  return (
    
    <div className="App">
      <header className="App-header">                         
        <h1> BasicPlaneSpotting</h1>                      
      </header>
      <QuizMaster />
      <FooterPage />
    </div>
  );
}

export default App;
