import React from "react";

const FooterPage = () => {
  return (
    <div className="footer">
          <a href="" target="_blank" className="fa fa-github" />
    </div>
  );
};

export default FooterPage;
