import React from 'react';
import Quiz from './Quiz';


class QuizMaster extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            questions:[
                'Airbus A320-NEO',
                'Embraer E175',
                'Airbus A220-300',    
                'Boeing 787-9',                              
                'Boeing 737-800',                             
                'Airbus A350-900', 
                'Airbus A321-NEO', 
                'Boeing 757-200',
                'Boeing 787-8'
            ],
            questionNr: 0,
                    /*[               
                         'Airbus A330-900NEO', 

                'Boeing 787-10', 
                'Boeing 737-700',
                'Boeing 737-800',
                'Airbus A320-NEO',
                'Airbus A330',
                'Airbus A321-NEO',
                'Airbus A340',
                'Airbus A380',
                'Airbus A350',
                'Bombardier CRJ900',
                'Boeing 757-200' ]*/
        }
        this.nextQuestion = this.nextQuestion.bind(this);
    }

    nextQuestion(event){
        
        let qNr = this.state.questionNr;
        let qLength = this.state.questions.length;
        if(qLength-2<qNr){
            qNr = 0;
        } else {
            qNr = qNr+1;
        }
        this.setState({
            questionNr: qNr, 
        });
    }

    render(){
        return <Quiz path="q/:test" onClickNext={this.nextQuestion} varient={this.state.questions[this.state.questionNr]} key={this.state.questions[this.state.questionNr]} />
    }
}

export default QuizMaster;