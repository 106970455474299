import React from "react";
import bootstrap from "../bootstrap.min.css";

class PlaneImg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgNum: Math.floor(Math.random() * 2) + 1
    };
  }

  render() {
    const imgPath = require(`../images/${this.props.varient}/${this.state.imgNum}.jpg`);
    return (
      <img src={imgPath} className="mainImg" alt="Plane" />
    );
  }
}

export default PlaneImg;
