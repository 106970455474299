import React from 'react';

class Nav extends React.Component {

    render() {

        return(
            <div>
                <button onClick={this.props.onClickNext} className="OptionBtn btn btn-success" >Next</button>
            </div>
        );
    }
}

export default Nav;